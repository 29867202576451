<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('market.table.settings.empty_text')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(actions)="data">
          <router-link
            v-if="$ability.can('update', 'admin:navigations')"
            :to="{ name: 'admin:navigations-update', params: { id: data.item.id } }"
            class="btn btn-primary btn-sm"
          >
            <feather-icon
              icon="EditIcon"
              size="16"
              class="align-middle"
            />
          </router-link>
          <span
            v-if="$ability.can('destroy', 'admin:navigations')"
            class="btn btn-danger btn-sm ml-1 hidden"
            @click="destroy(data.item.id)"
          >
            <feather-icon
              icon="TrashIcon"
              size="16"
              class="align-middle"
            />
          </span>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'

import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import useList from './useList'
import storeModule from './storeModule'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      tableColumns: [
        { key: 'title', label: this.$t('market.table.fields.title'), sortable: true },
        { key: 'actions', label: this.$t('market.table.fields.actions') },
      ],
    }
  },
  methods: {
    destroy(id) {
      this.confirm(() => {
        this.$http.delete(`/api/admin/navigations/${id}`)
          .then(() => this.refetchData())
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        title: this.$t('notifications.confirm.destroy.menu'),
        text: null,
      })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'navigations'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      statusFilter,
    } = useList()

    return {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
